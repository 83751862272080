.exp-sec {
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
    padding: 25px;
    background: #f7fcff;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 #01579b33;
    max-width: auto;
    overflow-wrap: break-word;
}

.expHeader{
    display: flex;
    justify-content: space-between;
}

.btn {
    margin-top: -6px;
    border: none;
    outline: none;
}

.btn:hover {
    outline: none;
    border: none;
}


.spec-exp {
    box-shadow: 0 2px 6px 0 #01579b33;
    z-index: 2;
}

.exp-tech-stack{
    font-size: 15px;
    color: #4f4e4e;
}

.exp-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;

}

.exp-content ul {
    overflow-wrap: break-word;
    margin-right: 0;
    word-wrap: break-word;
}