#about {
    margin-top: 70px;
}
.jumbotron {
    display: block;
    justify-content: center;
}

.pro-photo {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
}

@media (min-width: 350px) {
    .details {
        display: flex;
        margin-top: 10px;
        justify-content: left;
        align-items: center;
    }
    .about {
        align-items: center;
        padding-left: 12px;
    }
}

.degree {
    margin:-10px 0 0 0;
    font-size: 12px;
    color: #2a2a2a;
}

.school {
    font-size: 10px;
    margin-top: 5px;
}

.school a {
    color: #00000099;
    text-decoration:none;
}

.school a:hover {
    color: #0a66c2;
    text-decoration: underline;
}

.location {
    font-size: 10px;
    margin-top: 0;
    color: #00000099;
    text-decoration:none;
}

.hello {
    color: red;
    float: right;
    padding: 20px;
    max-width: 200px;
    margin-top: -80px;
    text-align: center;
    margin-right: 0px;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 #01579b33;
    
}
.social {
    font-size: 30px;
    display: flex;
    text-decoration: none;
    float: right;
    padding: 10px;
    max-width: 200px;
    max-height: 100px;
    margin-top: -90px;
    text-align: center;
    margin-right: 0px;
    border-radius: 5px;
    /* box-shadow: 0 2px 6px 0 #01579b33; */
}

.social a {
    color: #0b0b0b;
    margin: 0 10px 0 10px;
}

@media only screen and (max-width:561px){
    .social{
        display: none;
    }
}

.lead {
    margin-top: 10px;
}