.sec .hr {
    margin-top: 10px;
    color: #6bb8e48d;

}

.skills {
    margin: auto;
    display: flex;
    justify-content: center;
    padding-right: 30px;
    /* margin-left: 0; */
}

/* .skills::-webkit-scrollbar {
    display: none;
  } */

.skills .skill {
    max-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50px; */
    margin: auto;
    /* width: auto; */
    min-height: 40px;
    max-height: 80px;
    margin-left: 15px;
    margin-top: 10px;
    /* margin-right: 10px; */
    border-radius: 10px;
    transform: scale(1);
    background-color: transparent;
    background: #dae4eb;
    text-align: center;
    /* padding: 2px; */
}

.skills .skill:hover {
    transform: scale(1.02);
    transition: 0.4s;
}

.skill-img {
    padding: 20px;
    /* justify-content: center; */
    /* position: relative; */
    /* height: 50px; */
    max-width: 100px;
    max-height: 100px;
    /* min-width: 100px; */
    /* background-color: transparent; */
}

.col {
    margin-top: 100px;
}