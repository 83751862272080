.sec .form{
    margin-left: 10%;
    margin-right: 10%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-top: 10px;
}

.star {
    color: #ff0000;
}

.button {
    border-radius: 5px;
    margin-top: 15px;
    max-height: 10px;
    padding: 8px;
    padding-bottom: 33px;
    border: none;
    background: #007bff;
    color: #fff;
}

.button:hover {
    background: #046cdb;
}

.button:active {
    transform: scale(0.99);
    /* transition: 1s; */
}

.button:disabled{
    background: #8d9195;
}

.alert-success {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    max-height: 80px;
}

.sec #contactme-social {
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.sec #contactme-social a {
    color: #000000;
    margin: 0 10px 0 10px;
}
