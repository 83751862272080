.sec .hr {
    margin-top: 10px;
    color: #6bb8e48d;
}

.proj-title {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 578px) {
    .row .project {
        margin-left: 0px;
    }
}

.project-task {
    margin-left: -15px;
}
.project-task:first-child {
    margin-top: 14px;
}
.goback {
    margin-left: -15px;
    /* margin-bottom: 15px; */
}

.proj-link {
    margin-left: 10px;
    margin-top: 4px;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* background: #1f1e39; */
}

.projects::-webkit-scrollbar {
    display: none;
  }

.project {
    display: block;
    max-width: 300px;
    min-width: 300px;
    /* max-height: 350px; */
    margin: 10px;
    padding: 10px;
    padding-right: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px 0 #01579b33;
    /* box-shadow: 0 0 128px #0000001a,
                       0 32px 64px -48px #00000080;*/
    transform: scale(0.98);
    overflow-wrap: break-word;
    word-break: break-all;
}

.project .project-pic {
    max-width: 280px;
    min-width: 280px;
    max-height: 150px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.projects .project:first-child {
    margin-left: -10px;
}

.projects .project:last-child {
    margin-right: -9px;
}

.project:hover {
    transform: scale(1.02);
    transition: 0.4s;
    overflow-wrap: break-word;
    cursor: pointer;
    box-shadow: 3px 3px 8px 0 #01579b33;
}
.projects .texts {
    /* color: blue; */
    /* overflow-x: hidden; */
    overflow-wrap: break-word;
}

.icon{
    width: 100px;
}

.proj-wrapper {
    margin-top: -8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.proj-wrapper .swipe-right {
    background: #6bb8e441;
    color: #6bb8e48d;
    font-weight: 800;
    border: none;
    border-radius: 2px;
    /* display: block; */
    font-size: 25px;
    padding: 2px;
    text-align: center;
    margin-top: 100px;
    max-height: 100px;
    margin-right: -25px;
    width: 20px;
    /* height: 300px; */
    box-shadow: 0 2px 6px 0 #01579b33;

}
.proj-wrapper .swipe-left {
    background: #6bb8e441;
    color: #6bb8e48d;
    font-weight: 800;
    border: none;
    border-radius: 2px;
    /* display: none; */
    font-size: 25px;
    padding: 2px;
    text-align: center;
    margin-left: -25px;
    margin-top: 100px;
    max-height: 100px;
    width: 20px;
    /* height: 300px; */
    /* display: none; */
    box-shadow: 0 2px 6px 0 #01579b33;
}

.proj-wrapper button:hover {
    background: #6bb8e467;
}

.proj-wrapper button:active {
    transform: scale(0.98);
}


.project-links {
    margin-right: 10px;
}