.proj-sec {
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
    padding: 25px;
    /* background: #ffffff; */
    background: #f7fcff;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 #01579b33;
    max-width: auto;
    overflow-wrap: break-word;
    /* margin-right: 50%; */
}

.proj-header {
    margin-top: -6px;
    display: flex;
    justify-content: space-between;
    
}

.btn {
    margin-top: -6px;
    margin-right: -16px;
    border: none;
    outline: none;
}

.btn:hover {
    outline: none;
    border: none;
}


.spec-proj {
    box-shadow: 0 2px 6px 0 #01579b33;
    z-index: 2;
}

.proj-tech-stack{
    font-size: 15px;
    color: #4f4e4e;
}

.proj-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;

}

.proj-content ul {
    overflow-wrap: break-word;
    margin-right: 0;
    word-wrap: break-word;
}